import React, { useEffect, useState, useRef, useContext } from 'react';
// import { Link, useLocation, useParams } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import ProjectService from '../services/projects';
import Area from '../components/Area';
import ActivityRing from '../components/ActivityRing';
import { MobileContext } from '../components/MobileContext';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '../App.css';

const ProjectDetail = ({ user }) => {
    const [project, setProject] = useState([]);
    const [showRing, setShowRing] = useState(false);
    const [edit, setEdit] = useState(false);
    const [areas, setAreas] = useState([]);
    const [title, setTitle] = useState();
    const [zones, setZones] = useState();
    const dataFetchedRef = useRef(false);
    const params = useParams();
    const isMobile = useContext(MobileContext);
    const [thousand, setThousand] = useState();
    const [percentage, setPercentage] = useState();
    const [available, setAvailable] = useState();

    async function getProjectDetails() {
        ProjectService.getProjectDetails(user, params.id).then(result => {
            if(result.status === "200 OK"){
                // console.log("project details", result.data.project);
                setProject(result.data.project);
                setZones(result.data.project.areas.length);
                if(result.data.project.activity && result.data.project.activity[0].value !== 0){
                    setShowRing(true);
                }
                setAreas(result.data.project.areas);
                setTitle(result.data.project.name);
                setEdit(result.data.project.edit);
                setThousand(result.data.project.liters.thousand);
                setPercentage(result.data.project.liters.percentage);
                setAvailable(result.data.project.liters.available);
            } else {
                setAreas();
                console.log('Fetch error: ', result.status);
            }
        });
    }

    useEffect(() => {
        //we use useRef to keep track of a boolean. 
        //If it’s true, we’ve already been here, and we can return. 
        //If it is false, we can perform our fetch and then update the ref.
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getProjectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="Wrapper">
            <Link to="/projects"><Image src={'/arrow_left.svg'} alt="arrow left" /> terug naar project</Link>
            <Header>
                <Title>{title}</Title>
                <Summary><Number>{zones}</Number><Text>zones</Text></Summary>
                <Summary><Number>{percentage}%</Number><Text>gevuld</Text></Summary>
                <Summary><Number>{available}{thousand &&<>k.</>}</Number><Text>liter beschikbaar</Text></Summary>
                {/* {project.RU40 && <Summary><Number>{project.percentage_filled}%</Number><Text>gevuld</Text></Summary>}
                {project.RU40 && <Summary><Number>{project.netto_capacity_project}{thousand &&<>k.</>}</Number><Text>liter beschikbaar</Text></Summary>} */}
                {/* {!isMobile && <Button>+ Nieuw gebied</Button>} */}
            </Header>
            <div>
                <Project>
                    {!project.healthcheck && <Alert>Let op: laatste meting ouder dan 24 uur</Alert>}
                    {!isMobile && <><p>Postcode: {project.postal_code}</p><br/></>}
                    {!isMobile && <><p>Omschrijving:<br/>{project.description}</p><br/></>}
                    {/* {project.activity && showRing && <ActivityRing props={project.activity} title={title} isMobile={isMobile} />} */}
                    {/* {project.netto_capacity_project && <div><b>Capaciteit:</b> <Figure>{project.netto_capacity_project} L</Figure></div>} */}
                    {/* {project.netto_capacity_project &&
                        <>
                            <div>
                                <Figure>{project.netto_capacity_project} L</Figure> van <b>{project.total_capacity_project} L</b> beschikbaar
                            </div>
                        </>
                    } */}
                </Project>

                <Area data={areas} project={params.id} isMobile={isMobile} edit={edit}/>

            </div>
        </div>
    )
};

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: space-between;
`;

const Project = styled.div`
    margin: 24px 0px;
`;

const Alert = styled.div`
    background: #F8D7DA;
    padding: 18px;
    color: #77232B;
    // font-weight: bold;
    margin-bottom: 22px;
    font-size: 12px;
`;

const Title = styled.h1`
    margin-right: 50px;

    @media screen and (max-device-width: 400px){
        font-size: 14px;
    }

    @media screen and (min-device-width: 401px) and (max-device-width: 640px){
        font-size: 16px;
    }
`;

const Summary = styled.div`
    box-sizing: border-box;
    width: 150px;
`;

const Number = styled.div`
    font-size: 42px;
    font-weight: bold;
    border-bottom: 1px solid #000000;

    @media screen and (max-device-width: 400px){
        font-size: 24px;
    }
`;

const Text = styled.p`
    margin-top: 8px;
    font-size: 18px;
`;

const Image = styled.img`
    padding-top: 0.5rem;
    height: 16px;
    margin-right: 6px;
`

// const Button = styled.button`
//     min-width: 50px;
//     background: #1976D2;
//     border-radius: 8px;
//     padding: 0 20px;
//     border: none;
//     border-bottom: 1px solid #2A67D2;
//     color: #fff;

//     @media screen and (max-device-width: 400px){
//         padding: 4px 20px;
//     }

//     @media screen and (min-device-width: 401px) and (max-device-width: 640px){
//         padding: 10px 20px;
//     }
// `;

const Figure = styled.p`
    // margin-left:14px;
    // background: #6D757C;
    background: #2196F3;
    font-weight: bold;
    color: #fff;
    display: inline-block;
    padding: 8px;
    border-radius: 50px;
`;

export default withAuthenticator(ProjectDetail);