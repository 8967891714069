import React, { useState } from "react";
import UserPool from "./UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";
import Login from "./Login";
import styled from "styled-components";


const Signup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [verifycode, setVerifycode] = useState("");
    const [signup, setSignUp] = useState(false);
    const [verify, setVerify] = useState(false);
    const [verified, setVerified] = useState(false);
    const [error, setError] = useState(false);
    // const [UserAttributes, setUserAttributes] = useState([]);
    const [formDataEmail, setFormDataEmail] = useState({
        Name: "",
        Value: ""
      })
    const [formDataName, setFormDataName] = useState({
        Name: "",
        Value: ""
      })
    // UserAttributes: [{Name: "email", Value: "rodger.mol@gmail.com"}, {Name: "name", Value: "Rodger"}]
    // 0: {Name: "email", Value: "rodger.mol@gmail.com"}
    // Name: "email"
    // Value: "rodger.mol@gmail.com"
    // 1: {Name: "name", Value: "Rodger"}
    // Name:"name"
    // Value: "Rodger"

    const UserAttributes = [];

    // const UserAttributes = [];
    // function handleLastNameChange(e) {
    //     setName(e.target.value);
    //     setUserAttributes({ 'Name': 'name', 'Value': e.target.value });
    //     address: JSON.stringify(address)
    //     console.log(UserAttributes);
    //   }

    const onSubmit = (event) => {
        event.preventDefault();
        if(email && password && name){
            UserAttributes.push(formDataEmail, formDataName)
            UserPool.signUp(email, password, UserAttributes, null, (err, data) => {
                if (err) {
                    console.log(err);
                }
                setSignUp(true);
                setVerify(true);
                console.log(data);
            });
            const user = UserPool.getCurrentUser();
                    if (user) {
                        // user.getSession((err, session) => {
                        //     console.log("Session: ", session);
                        // })
                        console.log("user RODGER: ", user);
                    }
        }else{
            setError(true);
        }

    };

    const goBack = () => {
        setSignUp(false);
        setVerify(false);
        setVerified(false);
        window.location.reload(false);
    };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
        setFormDataEmail({...formDataEmail, Name: "email", Value: event.target.value});
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
        setFormDataName({...formDataName, Name: "name", Value: event.target.value})
    };
    
    // const pushArray = () => {
    //     UserAttributes.push(formDataEmail, formDataName)
    //     // console.log("UserAttributes: ", UserAttributes);
    // };

    const handleVerifySubmit = (event) => {
        event.preventDefault();
        verifyUser(email, verifycode, (err, result) => {
            if(err) {
                console.log(err);
                return;
            } else {
                console.log("result: ", result);
                setVerify(false);
                setVerified(true)
            }
            // console.log("result: ", result);
            // const user = UserPool.getCurrentUser();
            //     if (user) {
            //         user.getSession((err, session) => {
            //             console.log("Session: ", session);
            //         })
            //         console.log("user: ", user);
            //     }
            // if(result==="SUCCESS"){
            //     const user = UserPool.getCurrentUser();
            //     if (user) {
            //         console.log("user: ", user);
            //     }
            //     // setVerified(true);
            // }
        })

    };

    const verifyUser = (username, verifycode, callback) => {
        const userData = {
            Username: username,
            Pool: UserPool
        };

        const cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(verifycode, true, callback);
    }

    return (
        <Navbar.Wrapper>
        <Navbar.Logo><Image src={'/logo-rainup-pro_v2.svg'} alt="rainup pro logo" /></Navbar.Logo>
        <Title>Jouw online<br/>waterbeheer omgeving</Title>
        {error && <Alert>Vul alle velden in</Alert>}
        {!signup &&
            // <Navbar.Wrapper>
                
                <form onSubmit={onSubmit}>
                    <label htmlFor="email">Email</label>
                    <Input
                        value={email}
                        // onChange={(event) => setEmail(event.target.value)}
                        onChange={handleChangeEmail}
                    ></Input>
                    {/* <input onChange={(e) => setFormData1({...formData1, Name: "email", Value: e.target.value})} value={formData1.Value} type="text" name="title" id="title" /> */}
                    <label htmlFor="password">Wachtwoord</label>
                    <Input
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    ></Input>
                    <label htmlFor="name">Naam</label>
                    <Input
                        value={name}
                        // onChange={(event) => setName(event.target.value)}
                        onChange={handleChangeName}
                    ></Input>
                    {/* <input onChange={(e) => setFormData2({...formData2, Name: "name", Value: e.target.value})} value={formData2.value} type="text" name="name" id="name" /> */}
                    <ButtonSubmit type="submit">Maak account &#8594;</ButtonSubmit><Button type="submit" onClick={goBack}>ga terug naar inlogscherm</Button>
                </form>
            // </Navbar.Wrapper>
        }
        {verify &&
            <>
                <p>Je code is onderweg. Om in te loggen, voert u de code in die we u per e-mail hebben gestuurd. Het kan een minuut duren voordat deze arriveert.</p>
                <form onSubmit={handleVerifySubmit}>
                    <label htmlFor="verifycode">Bevestigingscode</label>
                    <Input
                        value={verifycode}
                        onChange={(event) => setVerifycode(event.target.value)}
                    ></Input>

                    <ButtonSubmit type="submit">Bevestig &#8594;</ButtonSubmit>
                </form>
            </>
        }
        {verified &&
            <Login />
        }
        </Navbar.Wrapper>
    )
};

export default Signup;

const Input = styled.input`
  background-image: linear-gradient(#20aee3, #20aee3), linear-gradient(#bfbfbf, #bfbfbf);
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  float: none;
  background-color: transparent;
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s;
  color: #bfbfbf;
  min-height: 35px;
  display: initial;
  width: 100%;
  outline: none;
  font-size: 15px;
  &:focus {
      background-size: 100% 2px, 100% 1px;
      outline: 0 none;
      transition-duration: 0.3s;
      color: #525252;
    }
`;

const Alert = styled.div`
    background: #F8D7DA;
    padding: 18px;
    color: #77232B;
    // font-weight: bold;
    margin-bottom: 22px;
    font-size: 12px;
`;

const Title = styled.div`
    font-size: 42px;
    margin-right: 50px;
    margin-bottom: 40px;
    font-family: 'SuisseIntl';
`;

const ButtonSubmit = styled.button`
    min-width: 50px;
    padding: 14px;
    background: #EB5A37;
    border-radius: 3px;
    border: none;
    margin-top: 20px;
    color: #fff;
    font-family: 'SuisseIntl';
`;

const Button = styled.button`
    min-width: 50px;
    background: none;
    border: none;
    float: right;
    margin-top: 35px;
    text-decoration: underline;
    font-family: 'SuisseIntl';
    font-size: 14px;
    color: #BDBDBD;
`;

const Navbar = {
    Wrapper: styled.nav`
      flex: 1;
  
      align-self: flex-start;
  
      padding: 1rem 3rem;
  
      display: flex;
      flex-direction: column;
  
      background-color: #E1E4E5;
    `,
    Logo: styled.h1`
    //   border: 1px solid gray;
    //   padding: 0.5rem 1rem;
    `,
    Items: styled.ul`
      display: flex;
      list-style: none;
    `,
    Itemx: styled.li`
      cursor: pointer;
      font-weight: bold;
      padding: 14px;
      background: #EB5A37;
      margin-right: 5px;
      border-radius: 4px;
    `,
    Item: styled.li`
      cursor: pointer;
      font-weight: bold;
      padding: 14px;
      background: #fff;
      margin-right: 5px;
      border-radius: 4px;
    `,
  };

  const Image = styled.img`
    padding-top: 0.5rem;
    height: 35px;
`;