import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import MeasurementsService from '../services/measurements';
import HighstockChartLevel from '../components/HighstockChartLevel';
import HighstockChartVwc from '../components/HighstockChartVwc';
import { withAuthenticator } from '@aws-amplify/ui-react';

const NodeDetail = ({ user }) => {
    const [node, setNode] = useState([]);
    const [model, setModel] = useState([]);
    const [data, setData] = useState([]);
    const [area, setArea] = useState([]);
    const [vwc, setVwc] = useState([]);
    const [temp, setTemp] = useState([]);
    const [battery, setBattery] = useState([]);
    const [healthcheck, setHealthcheck] = useState(false);
    const dataFetchedRef = useRef(false);
    const params = useParams();
    const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

    async function getMeasurements() {
        MeasurementsService.getMeasurements(user, params.id).then(result => {
            if(result.status === "200 OK"){
                // console.log("ok", result);
                setNode(result.node);
                setModel(result.node.model);
                setData(result.data);
                setVwc(result.data.vwc);
                setTemp(result.data.temp);
                setBattery(result.node.battery);
                setHealthcheck(result.node.healthcheck);
                setArea(result.node.area);
            } else {
                // setNode();
                console.log('Fetch error: ', result.status);
            }
        });
    }

    useEffect(() => {
        //we use useRef to keep track of a boolean. 
        //If it’s true, we’ve already been here, and we can return. 
        //If it is false, we can perform our fetch and then update the ref.
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getMeasurements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="Wrapper">
            <Link onClick={goBack}><Image src={'/arrow_left.svg'} alt="arrow left" /> terug naar project</Link>
            <Header>
                {!healthcheck ? <><Red /> sensor is down</> : <><Green /> sensor is up</>}
                <Item>Model: {node.model}</Item>
                <Item>Device id: {node.device_id}</Item>
            </Header>
            <div>
                {model==='RU40-NBIOT-EU' && <HighstockChartLevel data={data} area={area} />}
                {model==='RU13-NBIOT-EU' && <HighstockChartVwc vwc={vwc} temp={temp} area={area} />}
            </div>
            <div>
                {battery >= 4 && <Summary><Number>80-100%</Number><Text>batterij</Text></Summary>}
                {battery >= 3 && battery < 4 && <Summary><Number>60-80%</Number><Text>batterij</Text></Summary>}
                {battery >= 2 && battery < 3 && <Summary><Number>40-60%</Number><Text>batterij</Text></Summary>}
                {battery > 1 && battery < 2 && <Summary><Number>20-40%</Number><Text>batterij</Text></Summary>}
                {battery <= 1 && <Summary><Number>0-20%</Number><Text>batterij</Text></Summary>}
            </div>
        </div>
    )
};

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: 16px;
    // justify-content: space-between;
    // alignItems: stretch;
`;

const Image = styled.img`
    padding-top: 0.5rem;
    height: 12px;
  
`;

const Item = styled.div`
    box-sizing: border-box;
    margin-left: 14px;
`;

const Summary = styled.div`
    box-sizing: border-box;
    width: 150px;
    margin-top:16px;
`;

const Number = styled.div`
    font-size: 30px;
    font-weight: bold;
    border-bottom: 1px solid #000000;

    @media screen and (max-device-width: 400px){
        font-size: 24px;
    }
`;

const Text = styled.p`
    margin-top: 8px;
    font-size: 18px;
`;

const Green = styled.p`
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #00C537;
    margin-right: 5px;
    margin-top: 6px;
`;

const Red = styled.p`
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #EE1414;
    margin-right: 5px;
    margin-top: 6px;
`;

export default withAuthenticator(NodeDetail);